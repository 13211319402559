define("ember-quill/components/quill-editor", ["exports", "ember-quill/templates/components/quill-editor"], function (exports, _quillEditor) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  /* global Quill */

  const { Component, computed, getOwner, get, set } = Ember;

  exports.default = Component.extend({
    layout: _quillEditor.default,
    editor: null,

    options: computed(function () {
      return { theme: "snow" };
    }),

    safeValue: computed(function () {
      return Ember.String.htmlSafe(get(this, "value"));
    }),

    fastboot: computed(function () {
      return getOwner(this).lookup("service:fastboot");
    }),

    didInsertElement() {
      const self = this;

      // Don't instantiate Quill if fastboot is detected
      if (this.get("fastboot.isFastBoot")) {
        return;
      }

      const editor = new Quill(this.element, get(this, "options"));

      editor.on("text-change", (delta, oldDelta, source) => {

        //insert link as you type
        editor.on('text-change', function (delta, oldDelta, source) {
          self.sendAction("textChange", get(self, "editor"), delta, oldDelta, source);

          var regex = /https?:\/\/[^\s]+$/;
          if (delta.ops.length === 2 && delta.ops[0].retain && isWhitespace(delta.ops[1].insert)) {
            var endRetain = delta.ops[0].retain;
            var text = editor.getText().substr(0, endRetain);
            var match = text.match(regex);

            if (match !== null) {
              var url = match[0];

              var ops = [];
              if (endRetain > url.length) {
                ops.push({ retain: endRetain - url.length });
              }

              ops = ops.concat([{ delete: url.length }, { insert: url, attributes: { link: url } }]);

              editor.updateContents({
                ops: ops
              });
            }
          }
        });

        self.sendAction("textChange", get(self, "editor"), delta, oldDelta, source);
      });
      editor.on("selection-change", (delta, oldDelta, source) => {
        self.sendAction("selectionChange", get(self, "editor"), delta, oldDelta, source);
      });

      set(self, "editor", editor);
    }
  });


  function isWhitespace(ch) {
    var whiteSpace = false;
    if (ch == ' ' || ch == '\t' || ch == '\n') {
      whiteSpace = true;
    }
    return whiteSpace;
  }
});